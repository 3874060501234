<template>
  <div id="guider">
    <div class="header">
      <van-row gutter="10">
        <van-col span="8" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />导游管理
        </van-col>
        <van-col span="14" class="search">
          <input
            type="text"
            class="search-input"
            v-model="query.name"
            @blur="onRefresh"
          />
          <svg-icon
            :data_iconName="'search'"
            :className="'search-input-icon'"
          />
        </van-col>
        <van-col
          v-if="page_status == 200"
          span="2"
          style="text-align: right"
          @click="onAddClick"
        >
          <svg-icon :data_iconName="'add'" :className="'add-icon'" />
        </van-col>
      </van-row>
    </div>
      <van-row class="tabs-container">
      <van-col
        span="12"
        v-for="(item, index) in tabs"
        :key="index"
        :class="[item.type == query.status ? 'tab-active' : 'tab-default']"
        @click="onTabClick(item)"
        >{{ item.text }}</van-col
      >
    </van-row>
    <div class="guider-body">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_guides"
        >
          <van-row
            class="guider-body-item"
            v-for="(item, index) in list"
            :key="index"
            @click="onItemClick(item)"
          >
            <van-col span="5">
              <van-image
                class="item-avatar"
                round
                :src="item.avatar ? item.avatar : ''"
                ><template v-slot:error
                  ><span style="font-size: 10px">暂无头像</span></template
                ></van-image
              >
            </van-col>
            <van-col span="19">
              <div class="item-name">
                {{ item.name }}
                <span v-if="item.attach_status=='1'||item.attach_status=='2'">
                  <svg-icon
                  :data_iconName="'temp'"
                  :className="''"
                /></span>
                <div :class="[lanItem=='普通话'?'lang2':'lang']" 
                  v-for="lanItem, idx in item.language" :key="idx">
                  {{ lanItem }}
                </div>
                <van-rate
                  v-model="item.rate_star"
                  :size="10"
                  color="#FFAA00"
                  void-icon="star"
                  gutter="2px"
                  void-color="#fff"
                  readonly
                  allow-half
                />
              
              </div>
              <div class="item-sub-text">
                {{ item.mobile ? item.mobile : "暂无手机" }}
              </div>
              <div class="item-sub-text">
                {{ item.travel_company ? item.travel_company : "暂无旅行社" }}
              </div>
            </van-col>
          </van-row>
        </van-list>
        <van-empty v-if="list.length == 0&&loading==false" description="暂无导游数据" />
      </van-pull-refresh>
    </div>
    <Tarbar :active="1"></Tarbar>
  </div>
</template>

<script>
import { Toast } from 'vant';
import Tarbar from "../components/Tarbar.vue";
export default {
  name: "guider",
  components: {
    Tarbar,
  },
  data() {
    const _self = this
    return {
      list: [
      ],
      tabs: [
        {
          id: 0,
          text: "已添加",
          type: '1,2',
        },
        {
          id: 0,
          text: "待添加",
          type: '4',
        },
      ],
      loading: true,
      finished: false,
      refreshing: false,
      page_status: "",
      query: {
        page: 1,
        name: "",
        status:_self.$route.query.type?_self.$route.query.type:'1,2'
      },
    };
  },
  computed:{
    query1(){
      if(this.query.status=='1,2'){
        return {
          status:1,
          page:1
        }
      }
      if(this.query.status==4){
        return {
          status:0,
          page:1
        }
      }
      return {
         status:1,
         page:1
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    onTabClick(item) {
      this.query.status = item.type;
      this.onRefresh();
    },
    onAddClick() {
      this.$router.push({path:"/guider/add",query:{type:this.query.status}});
    },
    onItemClick(item) {
      var relation_id =""
      if(item.relation){
        relation_id = item.relation.id
      }
      this.$router.push({
        path: "/guider/item",
        query: {
          id: item.id,
          type:this.query.status,
          relation_id:relation_id?relation_id:''
        },
      });
    },

    async get_guides() {
      const res = await this.$apis.get_guides(this.query);
      const res1 = await this.$apis.get_tmp_guides(this.query1)

      if (res.status == 403) {
        this.page_status = 403;
        return false;
      } else {
        this.page_status = 200;
      }
      if (res.next_page) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].language = res.data[i].language.split(',')
        this.list.push(res.data[i]);
      }
      if (res1.next_page) {
        this.query1.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      for (var i = 0; i < res1.data.length; i++) {
        res1.data[i].guide.relation = res1.data[i].relation
        res1.data[i].guide.language = res1.data[i].guide.language.split(',')
        this.list.push(res1.data[i].guide);
      }
     
       
    },

    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_guides();
    },
  },
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#guider {
  height: 100vh;
  /* overflow-y: hidden; */
  padding-top:10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
  
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.guider-body {
  background: #ffff;
  height: 80vh;
  padding: 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.guider-body-item {
  width: 100%;
  height: 1.6rem;
  position: relative;
  /* background: chartreuse; */
  z-index: 10;
}
.guider-body-item .item-avatar {
 
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.guider-body-item .item-avatar img {
  height: 100%;
  height: 100%;
}
.guider-body-item .item-name {
  font-size: 16px;
  color: #333;
 
  font-weight: bold;
}
.guider-body-item .item-sub-text {
  color: #666;
  font-size: 12px;
  
}
.lang {
  background: rgba(148, 174, 64, 0.18);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #94ae40;
  font-size: 12px;
  margin-left: 3px;
  margin-right: 3px;
}
.lang2 {
  background: rgba(75, 183, 78, 0.13);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #4bb74e;
  font-size: 12px;
  margin-left: 3px;
  margin-right: 3px;
}
.add-icon {
  font-size: 0.6rem;
}
.tabs-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 10vh;
  line-height: 10vh;
  background-color: #fff;
  margin-top: 0.4rem;
}
.tab-default {
  text-align: center;
  font-size: 0.3rem;
  color: #999;
  font-weight: bold;
}
.tab-active {
  text-align: center;
  font-size: 0.3rem;
  color: #333;
  font-weight: bold;
}.tabs-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 10vh;
  line-height: 10vh;
  background-color: #fff;
  margin-top: 0.4rem;
}
.tab-default {
  text-align: center;
  font-size: 0.3rem;
  color: #999;
  font-weight: bold;
}
.tab-active {
  text-align: center;
  font-size: 0.3rem;
  color: #333;
  font-weight: bold;
}
.tabs-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 10vh;
  line-height: 10vh;
  background-color: #fff;
  margin-top: 0.4rem;
}
.tab-default {
  text-align: center;
  font-size: 0.3rem;
  color: #999;
  font-weight: bold;
}
.tab-active {
  text-align: center;
  font-size: 0.3rem;
  color: #333;
  font-weight: bold;
}
</style>
